<app-page>
    <table>
        <tr>
            <td>
                <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll()"></app-action>
            </td>
            <td>
                <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll()"></app-action>
            </td>
        </tr>
    </table>

    <mat-accordion multi>
        <!-- ------------------------------------------------------------------ -->
        <!-- Backfill -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel *ngIf="sessionService.getIsSuperuser()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'backfill' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div appFieldContainer>
                <div>
                    <mat-checkbox [formControl]="backfill.businesses">
                        {{ 'backfill_businesses' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.cache">
                        {{ 'backfill_cache' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.campaigns">
                        {{ 'backfill_campaigns' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.checkIns">
                        {{ 'backfill_check_ins' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.deliveries">
                        {{ 'backfill_deliveries' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.devices">
                        {{ 'backfill_devices' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.clientFeedback">
                        {{ 'backfill_client_feedback' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.locations">
                        {{ 'backfill_locations' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.locationBusinessHours">
                        {{ 'backfill_location_business_hours' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.locationMetrics">
                        {{ 'backfill_location_metrics' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.metrics">
                        {{ 'backfill_metrics' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.orders">
                        {{ 'backfill_orders' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.pointsOfService">
                        {{ 'backfill_points_of_service' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.resources">
                        {{ 'backfill_resources' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.reviews">
                        {{ 'backfill_reviews' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.userRoles">
                        {{ 'backfill_user_roles' | translate }}
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox [formControl]="backfill.users">
                        {{ 'backfill_users' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div appFieldContainer>
                <app-action [text]="'submit' | translate"
                    [disabled]="!backfill.businesses.value && !backfill.cache.value && !backfill.campaigns.value && !backfill.checkIns.value && !backfill.deliveries.value && !backfill.devices.value && !backfill.clientFeedback.value && !backfill.locations.value && !backfill.locationBusinessHours.value && !backfill.locationMetrics.value && !backfill.metrics.value && !backfill.orders.value && !backfill.pointsOfService.value && !backfill.resources.value && !backfill.reviews.value && !backfill.userRoles.value && !backfill.users.value"
                    (onAsyncExecute)="doBackfill($event)">
                </app-action>
            </div>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Custom Backfill -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel *ngIf="sessionService.getIsSuperuser()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'custom_backfill' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div appFieldContainer>
                <app-action [text]="'submit' | translate"
                    (onExecute)="customBackfill()">
                </app-action>
            </div>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Order Receipt -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'order_receipt' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div appFieldContainer>
                <mat-form-field appField>
                    <mat-label>{{ 'order_id' | translate }}</mat-label>
                    <input matInput name="OrderId" [formControl]="orderReceipt.orderId" required>
                    <mat-error *ngIf="orderReceipt.orderId.invalid">{{formService.getFieldErrorMessage(orderReceipt.orderId)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="orderReceipt.orderId.invalid" (onExecute)="openOrderReceipt(orderReceipt.orderId.value)">
                </app-action>
            </div>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Read Proto -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'read_proto' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form #readProtoForm method="POST" [action]="protoRead.actionUrl" target="_blank" appFieldContainer>
                <input type="hidden" name="AuthToken" [value]="sessionService.getAuthToken()"/>
                <mat-form-field appField>
                    <mat-label>{{ 'proto_type' | translate }}</mat-label>
                    <mat-select [formControl]="protoRead.protoType" required>
                        <mat-option *ngFor="let protoType of PROTO_TYPES; index as i" [value]="PROTO_TYPES_VALUES[i]">
                            {{ protoType }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <br>
                <mat-form-field appField>
                    <mat-label>{{ 'proto_id' | translate }}</mat-label>
                    <input matInput name="ProtoId" [formControl]="protoRead.protoId" required>
                    <mat-error *ngIf="protoRead.protoId.invalid">{{formService.getFieldErrorMessage(protoRead.protoId)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="protoRead.protoId.invalid" (onExecute)="readProtoForm.submit()">
                </app-action>
            </form>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Write Proto -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel *ngIf="sessionService.getIsSuperuser()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'write_proto' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form #writeProtoForm method="POST" [action]="protoWrite.actionUrl" target="_blank" appFieldContainer>
                <input type="hidden" name="AuthToken" [value]="sessionService.getAuthToken()"/>
                <mat-form-field appField>
                    <mat-label>{{ 'proto_type' | translate }}</mat-label>
                    <mat-select [formControl]="protoWrite.protoType" required>
                        <mat-option *ngFor="let protoType of PROTO_TYPES; index as i" [value]="PROTO_TYPES_VALUES[i]">
                            {{ protoType }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <br>
                <mat-form-field appField>
                    <mat-label>{{ 'text_proto' | translate }}</mat-label>
                    <textarea matInput name="textproto" [formControl]="protoWrite.textProto" required></textarea>
                    <mat-error *ngIf="protoWrite.textProto.invalid">{{formService.getFieldErrorMessage(protoWrite.textProto)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="protoWrite.textProto.invalid" (onExecute)="writeProtoForm.submit()">
                </app-action>
            </form>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Generate QR Code -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'generate_qr_code' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form #generateQrCodeForm method="POST" [action]="EnvironmentUtil.resolveBackendUrl('/service/user/qrcode/encode')" target="_blank" appFieldContainer>
                <input type="hidden" name="Auth-Token" [value]="sessionService.getAuthToken()"/>
                <mat-form-field appField>
                    <mat-label>{{ 'text_to_encode' | translate }}</mat-label>
                    <input matInput name="data" [formControl]="generateQrCode.textToEncode" required>
                    <mat-error *ngIf="generateQrCode.textToEncode.invalid">{{formService.getFieldErrorMessage(generateQrCode.textToEncode)}}</mat-error>
                </mat-form-field>
                <br>
                <app-action [text]="'submit' | translate" [disabled]="generateQrCode.textToEncode.invalid" (onExecute)="generateQrCodeForm.submit()">
                </app-action>
            </form>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Encrypt -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel *ngIf="sessionService.getIsSuperuser()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'encrypt' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table-layout [columns]="deviceService.ifMobile(1).orElse(2)" [items]="2">
                <table #layout appFieldContainer>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'data' | translate }}</mat-label>
                                <input matInput [formControl]="encryption.data" required>
                                <mat-error *ngIf="encryption.data.invalid">{{formService.getFieldErrorMessage(encryption.data)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <app-action [text]="'submit' | translate"
                                    (onAsyncExecute)="encrypt($event)"
                                    [disabled]="encryption.data.invalid">
                            </app-action>
                        </td>
                    </tr>
                </table>
                <div #layout>
                    {{ 'response' | translate}}:
                    <p>
                    <mat-form-field appField>
                        <textarea matInput readonly>{{ encryption.response }}</textarea>
                    </mat-form-field>
                </div>
            </app-table-layout>
        </mat-expansion-panel>
        <!-- ------------------------------------------------------------------ -->
        <!-- Decrypt -->
        <!-- ------------------------------------------------------------------ -->
        <mat-expansion-panel *ngIf="sessionService.getIsSuperuser()">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'decrypt' | translate }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table-layout [columns]="deviceService.ifMobile(1).orElse(2)" [items]="2">
                <table #layout appFieldContainer>
                    <tr>
                        <td>
                            <mat-form-field appField>
                                <mat-label>{{ 'data' | translate }}</mat-label>
                                <input matInput [formControl]="decryption.encryptedData" required>
                                <mat-error *ngIf="decryption.encryptedData.invalid">{{formService.getFieldErrorMessage(decryption.encryptedData)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <app-action [text]="'submit' | translate"
                                    (onAsyncExecute)="decrypt($event)"
                                    [disabled]="decryption.encryptedData.invalid">
                            </app-action>
                        </td>
                    </tr>
                </table>
                <div #layout>
                    {{ 'response' | translate}}:
                    <p>
                    <mat-form-field appField>
                        <textarea matInput readonly>{{ decryption.response }}</textarea>
                    </mat-form-field>
                </div>
            </app-table-layout>
        </mat-expansion-panel>
    </mat-accordion>
</app-page>
