import * as proto from 'src/proto/compiled-protos';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { SessionService } from '../../services/session.service';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormService } from '../../../general/services/form.service';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';
import { EnvironmentUtil } from '../../util/environment-util';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { ProtoType } from '../../util/constants';
import { MatSelectModule } from '@angular/material/select';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { NavigationService } from '../../services/navigation.service';
import { DeviceService } from 'src/app/general/services/device.service';
import { ActionObserver } from 'src/app/general/components/action/action';
import { SuccessResult, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Util } from 'src/app/general/util/util';
import { DialogService } from 'src/app/general/services/dialog.service';
import { ToastService } from 'src/app/general/services/toast.service';

@Component({
  selector: 'app-admin-user-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    MatExpansionModule,
    TranslateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    LoadableContentComponent,
    TableLayoutComponent,
    FieldContainerDirective,
    FieldDirective,
    MatSelectModule,
    ActionComponent,
    MatCheckboxModule
  ],
  templateUrl: './admin-user-page.component.html',
  styleUrls: ['./admin-user-page.component.css', '../../../../styles.css']
})
export class AdminUserPageComponent implements OnInit {
  readonly EnvironmentUtil = EnvironmentUtil;
  readonly PROTO_TYPES: Array<String> = Object.keys(ProtoType);
  readonly PROTO_TYPES_VALUES: Array<String> = Object.values(ProtoType);

  // This is set after the view is initialized
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  backfill = new (class {
    businesses: FormControl = new FormControl(false);
    cache: FormControl = new FormControl(false);
    campaigns: FormControl = new FormControl(false);
    checkIns: FormControl = new FormControl(false);
    deliveries: FormControl = new FormControl(false);
    devices: FormControl = new FormControl(false);
    clientFeedback: FormControl = new FormControl(false);
    locations: FormControl = new FormControl(false);
    locationBusinessHours: FormControl = new FormControl(false);
    locationMetrics: FormControl = new FormControl(false);
    metrics: FormControl = new FormControl(false);
    orders: FormControl = new FormControl(false);
    pointsOfService: FormControl = new FormControl(false);
    resources: FormControl = new FormControl(false);
    reviews: FormControl = new FormControl(false);
    userRoles: FormControl = new FormControl(false);
    users: FormControl = new FormControl(false);
  })();

  orderReceipt = new (class {
    orderId: FormControl = new FormControl('', [Validators.required]);
  })();

  protoRead = new (class {
    baseActionUrl: string = EnvironmentUtil.resolveBackendUrl('/service/debug/proto/read');
    actionUrl: string = this.baseActionUrl + '/' + ProtoType.ORDER;
    protoTypes: Array<String> = Object.keys(ProtoType);
    protoType: FormControl = new FormControl(ProtoType.ORDER);
    protoId: FormControl = new FormControl('', [Validators.required]);
  })();

  protoWrite = new (class {
    baseActionUrl: string = EnvironmentUtil.resolveBackendUrl('/service/debug/proto/write');
    actionUrl: string = this.baseActionUrl + '/' + ProtoType.ORDER;
    protoTypes: Array<String> = Object.keys(ProtoType);
    protoType: FormControl = new FormControl(ProtoType.ORDER);
    textProto: FormControl = new FormControl('', [Validators.required]);
  })();

  generateQrCode = new (class {
    textToEncode: FormControl = new FormControl('', [Validators.required]);
  })();

  userEmailUpdate = new (class {
    userId: FormControl = new FormControl('', [Validators.required]);
    newEmail: FormControl = new FormControl('', [Validators.required]);
  })();

  encryption = new (class {
    data: FormControl = new FormControl('', [Validators.required]);
    response: string = '';
  })();

  decryption = new (class {
    encryptedData: FormControl = new FormControl('', [Validators.required]);
    response: string = '';
  })();

  constructor(
      public formService: FormService,
      public sessionService: SessionService,
      public deviceService: DeviceService,
      private navigationService: NavigationService,
      private backendService: BackendService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private toastService: ToastService) {
    this.protoRead.protoType.valueChanges.subscribe(() => {
      this.protoRead.actionUrl = this.protoRead.baseActionUrl + '/' + this.protoRead.protoType.value;
    });

    this.protoWrite.protoType.valueChanges.subscribe(() => {
      this.protoWrite.actionUrl = this.protoWrite.baseActionUrl + '/' + this.protoWrite.protoType.value;
    });
  }

  ngOnInit(): void {
    this.sessionService.enforceAuthentication();
  }

  public openOrderReceipt(orderId: string): void {
    this.navigationService.openInNewTab(EnvironmentUtil.resolveBackendUrl('/service/user/order/receipt/' + orderId));
  }

  public doBackfill(actionObserver: ActionObserver): void {
    const request = new proto.waiternow.common.BackfillActionProto.Request();
    request.backfillBusinesses = this.backfill.businesses.value;
    request.backfillCache = this.backfill.cache.value;
    request.backfillCampaigns = this.backfill.campaigns.value;
    request.backfillCheckIns = this.backfill.checkIns.value;
    request.backfillDeliveries = this.backfill.deliveries.value;
    request.backfillDevices = this.backfill.devices.value;
    request.backfillClientFeedback = this.backfill.clientFeedback.value;
    request.backfillLocations = this.backfill.locations.value;
    request.backfillLocationBusinessHours = this.backfill.locationBusinessHours.value;
    request.backfillLocationMetrics = this.backfill.locationMetrics.value;
    request.backfillMetrics = this.backfill.metrics.value;
    request.backfillOrders = this.backfill.orders.value;
    request.backfillPointsOfService = this.backfill.pointsOfService.value;
    request.backfillResources = this.backfill.resources.value;
    request.backfillReviews = this.backfill.reviews.value;
    request.backfillUserRoles = this.backfill.userRoles.value;
    request.backfillUsers = this.backfill.users.value;

    this.backendService.backfill(
      request,
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        if (this.sessionService.handleAuthenticationError(error)) {
          return;
        }
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }

  public customBackfill(): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_backfill').subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.customBackfill(
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('success_operation').subscribe(text => this.toastService.success(text));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_internal').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }

  public encrypt(actionObserver: ActionObserver): void {
    this.encryption.response = '';
    this.backendService.encrypt(
      this.encryption.data.value,
      /* onSuccess= */ encryptedData => {
        this.encryption.response = Util.safeString(encryptedData);
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        if (this.sessionService.handleAuthenticationError(error)) {
          return;
        }
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'internal_error'));
      }
    );
  }

  public decrypt(actionObserver: ActionObserver): void {
    this.decryption.response = '';
    this.backendService.decrypt(
      this.decryption.encryptedData.value,
      /* onSuccess= */ data => {
        this.decryption.response = Util.safeString(data);
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        if (this.sessionService.handleAuthenticationError(error)) {
          return;
        }
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'internal_error'));
      }
    );
  }
}
