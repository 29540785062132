<app-page>
    <table align="center"><tr><td><app-progress *ngIf="isInProgress"></app-progress></td></tr></table>
    <div>
        <table width="100%">
            <tr>
                <td width="50%">
                    <table>
                        <tr>
                            <td>
                                <app-action [text]="'expand_all' | translate" [defaultStyle]=true (onExecute)="accordion.openAll()"></app-action>
                            </td>
                            <td>
                                <app-action [text]="'collapse_all' | translate" [defaultStyle]=true (onExecute)="accordion.closeAll()"></app-action>
                            </td>
                        </tr>
                    </table>
                </td>
                <td width="50%">
                    <table width="100%">
                        <tr>
                            <td>
                                <app-right-aligned-compact-content>
                                    <mat-button-toggle-group (change)="onCountryChange($event)">
                                        <mat-button-toggle value="us" checked>{{ 'country_us' | translate }}</mat-button-toggle>
                                        <mat-button-toggle value="mx">{{ 'country_mexico' | translate }}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </app-right-aligned-compact-content>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    </div>
    <mat-accordion multi>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_paid_orders' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="paidOdersChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #paidOdersChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="paidOdersChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #paidOdersChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="paidOdersChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #paidOdersChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_waiternow_revenue' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="waiterNowRevenueChartDataDaily" [options]="currencyChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #waiterNowRevenueChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="waiterNowRevenueChartDataMonthly" [options]="currencyChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #waiterNowRevenueChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="waiterNowRevenueChartDataYearly" [options]="currencyChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #waiterNowRevenueChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_payments_volume' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="paymentsVolumeChartDataDaily" [options]="currencyChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #paymentsVolumeChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="paymentsVolumeChartDataMonthly" [options]="currencyChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #paymentsVolumeChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="paymentsVolumeChartDataYearly" [options]="currencyChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #paymentsVolumeChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_refunds_volume' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="refundsVolumeChartDataDaily" [options]="currencyChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #refundsVolumeChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="refundsVolumeChartDataMonthly" [options]="currencyChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #refundsVolumeChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="refundsVolumeChartDataYearly" [options]="currencyChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #refundsVolumeChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_check_ins' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="checkInsChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #checkInsChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="checkInsChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #checkInsChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="checkInsChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #checkInsChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_orders' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="ordersChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #ordersChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="ordersChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #ordersChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="ordersChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #ordersChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_online_pickup_orders' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="onlinePickupOrdersChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #onlinePickupOrdersChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="onlinePickupOrdersChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #onlinePickupOrdersChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="onlinePickupOrdersChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #onlinePickupOrdersChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_delivery_orders' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="deliveryOrdersChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #deliveryOrdersChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="deliveryOrdersChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #deliveryOrdersChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="deliveryOrdersChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #deliveryOrdersChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_incomplete_orders' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="incompleteOrdersChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #incompleteOrdersChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="incompleteOrdersChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #incompleteOrdersChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="incompleteOrdersChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #incompleteOrdersChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_refunded_orders' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="refundedOrdersChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #refundedOrdersChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="refundedOrdersChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #refundedOrdersChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="refundedOrdersChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #refundedOrdersChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_new_locations' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="newLocationsChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #newLocationsChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="newLocationsChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #newLocationsChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="newLocationsChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #newLocationsChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_locations_enrolled_in_payments' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="locationsEnrolledInPaymentsChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #locationsEnrolledInPaymentsChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="locationsEnrolledInPaymentsChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #locationsEnrolledInPaymentsChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="locationsEnrolledInPaymentsChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #locationsEnrolledInPaymentsChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_reviews_comments' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="reviewsRatingsChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #reviewsRatingsChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="reviewsRatingsChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #reviewsRatingsChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="reviewsRatingsChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #reviewsRatingsChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ 'metrics_reviews_ratings' | translate: { periodType: '' } }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-grid-list [cols]="deviceService.ifMobile(1).orElse(3)" rowHeight="2:1">
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_daily_charts' | translate }}" *ngIf="errorLoadingDailyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="reviewsCommentsChartDataDaily" [options]="integerChartOptions" [type]="'line'" *ngIf="!errorLoadingDailyCharts" #reviewsCommentsChartDailyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_monthly_charts' | translate }}" *ngIf="errorLoadingMonthlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="reviewsCommentsChartDataMonthly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingMonthlyCharts" #reviewsCommentsChartMonthlyRef></canvas>
                </mat-grid-tile>
                <mat-grid-tile>
                    <mat-icon color="warn" matTooltip="{{ 'error_fetching_yearly_charts' | translate }}" *ngIf="errorLoadingYearlyCharts">error_outline</mat-icon>
                    <canvas baseChart class="chart" [data]="reviewsCommentsChartDataYearly" [options]="integerChartOptions" [type]="'bar'" *ngIf="!errorLoadingYearlyCharts" #reviewsCommentsChartYearlyRef></canvas>
                </mat-grid-tile>
            </mat-grid-list>
        </mat-expansion-panel>
    </mat-accordion>
</app-page>
