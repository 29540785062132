import * as proto from 'src/proto/compiled-protos';
import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { NavigationService } from "../services/navigation.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Consumer } from "src/app/general/interfaces/functions";
import { ToastService } from "src/app/general/services/toast.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import { EnvironmentUtil } from "../util/environment-util";

// See also ManagedBusinessToolbarModel, they have the same actions.

export class BusinessToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  toastService: ToastService;
  compactForMobile: boolean;
  onBusinessDeleted: Consumer<string>;

  public actions: Array<ToolbarAction<proto.waiternow.common.IBusinessProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.IBusinessProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      toastService: ToastService,
      compactForMobile: boolean,
      onBusinessDeleted: Consumer<string>) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService;
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.compactForMobile = compactForMobile;
    this.onBusinessDeleted = onBusinessDeleted;

    this.actions =
    [
      ToolbarUtil.createToolbarActionEdit(
          business => this.navigationService.goToEditBusinessPage(Util.safeString(business.id))),
      ToolbarUtil.createToolbarActionDelete(
          business => this.deleteBusiness(business)),
      {
        icon: 'edit_note',
        tooltipTranslateId: 'edit_menu',
        onExecute: business => this.navigationService.goToEditBusinessMenuPage(Util.safeString(business?.id))
      },
      {
        icon: 'person',
        tooltipTranslateId: 'users',
        onExecute: business => this.navigationService.goToBusinessUsersPage(Util.safeString(business?.id))
      },
      {
        icon: 'house',
        tooltipTranslateId: 'locations',
        onExecute: business => this.navigationService.goToBusinessLocationsPage(Util.safeString(business?.id))
      },
      {
        icon: 'campaign',
        tooltipTranslateId: 'campaigns',
        onExecute: business => this.navigationService.goToBusinessCampaignsPage(Util.safeString(business?.id))
      },
      ToolbarUtil.createToolbarActionViewProto(ProtoType.BUSINESS, business => Util.safeString(business.id), this.sessionService),
      {
        icon: 'newspaper',
        tooltipTranslateId: 'view_structured_menu_proto',
        formPostActionToOpenInNewWindowProvider: business => {
          return {
            url: EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/get_as_text/business/' + business?.id),
            formParams: [sessionService.getAuthTokenFormParam()]
          };
        }
      }
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.IBusinessProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
            business => this.navigationService.goToBusinessPage(Util.safeString(business.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private deleteBusiness(business: proto.waiternow.common.IBusinessProto): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_business', { name: business.businessName }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.deleteBusiness(
            Util.safeString(business.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('business_deleted').subscribe(text => this.toastService.success(text));
              this.onBusinessDeleted(Util.safeString(business.id));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_business').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
