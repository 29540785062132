import * as proto from 'src/proto/compiled-protos';
import { Util } from "src/app/general/util/util";
import { BackendService } from "../services/backend.service";
import { NavigationService } from "../services/navigation.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { Consumer } from "src/app/general/interfaces/functions";
import { ToastService } from "src/app/general/services/toast.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { ToolbarUtil } from "../util/toolbar-util";
import { ActionObserver } from "src/app/general/components/action/action";
import { ErrorResult, SuccessResult } from "src/app/general/util/result";
import { EnvironmentUtil } from "../util/environment-util";

export class LocationToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  toastService: ToastService;
  compactForMobile: boolean;
  onLocationDeleted: Consumer<string>;

  public actions: Array<ToolbarAction<proto.waiternow.common.ILocationProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.ILocationProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      toastService: ToastService,
      compactForMobile: boolean,
      onLocationDeleted: Consumer<string>) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService;
    this.dialogService = dialogService;
    this.toastService = toastService;
    this.compactForMobile = compactForMobile;
    this.onLocationDeleted = onLocationDeleted;

    this.actions =
    [
      {
        icon: 'business',
        tooltipTranslateId: 'go_to_business',
        onExecute: location => this.navigationService.goToBusinessPage(Util.safeString(location?.businessId))
      },
      ToolbarUtil.createToolbarActionEdit(
          location => this.navigationService.goToEditLocationPage(Util.safeString(location.id))),
      ToolbarUtil.createToolbarActionDelete(
          location => this.deleteLocation(location)),
      {
        icon: 'edit_note',
        tooltipTranslateId: 'edit_menu',
        onExecute: location => this.navigationService.goToEditLocationMenuPage(Util.safeString(location?.id))
      },
      {
        icon: 'campaign',
        tooltipTranslateId: 'campaigns',
        onExecute: location => this.navigationService.goToLocationCampaignsPage(Util.safeString(location?.id))
      },
      {
        icon: 'table_bar',
        tooltipTranslateId: 'points_of_service',
        onExecute: location => this.navigationService.goToLocationPointsOfServicePage(Util.safeString(location?.id))
      },
      {
        icon: 'list',
        tooltipTranslateId: 'orders',
        onExecute: location => this.navigationService.goToLocationOrdersPage(Util.safeString(location?.id))
      },
      {
        icon: 'subdirectory_arrow_left',
        tooltipTranslateId: 'refunded_orders',
        onExecute: location => this.navigationService.goToLocationRefundedOrdersPage(Util.safeString(location?.id))
      },
      {
        icon: 'playlist_remove',
        tooltipTranslateId: 'disputed_orders',
        onExecute: location => this.navigationService.goToLocationDisputedOrdersPage(Util.safeString(location?.id))
      },
      {
        icon: 'qr_code',
        tooltipTranslateId: 'download_qr_codes',
        onExecute: location => this.navigationService.goToLocationQrCodesPage(Util.safeString(location?.id))
      },
      {
        icon: 'phonelink_ring',
        tooltipTranslateId: 'devices',
        onExecute: location => this.navigationService.goToLocationDevicesPage(Util.safeString(location?.id))
      },
      {
        icon: 'bar_chart',
        tooltipTranslateId: 'metrics',
        onExecute: location => this.navigationService.goToLocationMetricsPage(Util.safeString(location?.id))
      },
      {
        icon: 'payments',
        tooltipTranslateId: 'enroll_in_payments',
        onExecute: location => {
          if (location) {
            const queryParams = '?location-id=' + location.id + '&business-name=' + location.redundantData?.businessName + '&location-name=' + location.name;
            this.navigationService.openInNewTab(EnvironmentUtil.resolveBackendUrl('/html/PaymentsEnrollment.html' + queryParams));
          }
        }
      },
      {
        icon: 'payment',
        tooltipTranslateId: 'verify_payments_enrollment',
        onAsyncExecute: (location, actionObserver) => this.verifyPaymentsEnrollment(location, actionObserver)
      },
      {
        icon: 'delivery_dining',
        tooltipTranslateId: 'enroll_in_delivery',
        onAsyncExecute: (location, actionObserver) => this.enrollLocationInDelivery(location, actionObserver)
      },
      ToolbarUtil.createToolbarActionViewProto(ProtoType.LOCATION, location => Util.safeString(location.id), this.sessionService),
      ToolbarUtil.createToolbarActionViewProtoWithTooltipAndIcon(
        'text_snippet',
        'view_business_hours_proto',
        ProtoType.LOCATION_BUSINESS_HOURS,
        location => Util.safeString(location.id), this.sessionService
      ),
      {
        icon: 'newspaper',
        tooltipTranslateId: 'view_structured_menu_proto',
        formPostActionToOpenInNewWindowProvider: location => {
          return {
            url: EnvironmentUtil.resolveBackendUrl('/service/user/menu/structured/get_as_text/location/' + location?.id),
            formParams: [sessionService.getAuthTokenFormParam()]
          };
        }
      }
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.ILocationProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
              location => this.navigationService.goToLocationPage(Util.safeString(location.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private verifyPaymentsEnrollment(location: proto.waiternow.common.ILocationProto | undefined, actionObserver: ActionObserver) {
    if (!location) {
      actionObserver.onError(ErrorResult.empty());
    }
    this.backendService.verifyPaymentsEnrollment(
      Util.safeString(location?.id),
      /* onSuccess= */ isEnrolled => {
        if (isEnrolled) {
          actionObserver.onSuccess(SuccessResult.withMessageTranslateId('location_enrolled_in_payments'));
        } else {
          actionObserver.onError(ErrorResult.withMessageTranslateId('location_not_enrolled_in_payments'));
        }
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_verifying_payments_enrollment'));
      }
    );
  }

  private enrollLocationInDelivery(location: proto.waiternow.common.ILocationProto | undefined, actionObserver: ActionObserver) {
    if (!location) {
      actionObserver.onError(ErrorResult.empty());
    }
    this.backendService.enrollLocationInDelivery(
      Util.safeString(location?.id),
      /* onSuccess= */ () => {
        actionObserver.onSuccess(SuccessResult.withMessageTranslateId('enrolled_in_delivery'));
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_enrolling_in_delivery'));
      }
    );
  }

  private deleteLocation(location: proto.waiternow.common.ILocationProto): void {
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_delete_location', { name: location.redundantData?.businessName + ' '  + location.name }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.deleteLocation(
            Util.safeString(location.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('location_deleted').subscribe(text => this.toastService.success(text));
              this.onLocationDeleted(Util.safeString(location.id));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_deleting_location').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }
}
