import * as proto from 'src/proto/compiled-protos';
import { Util } from "src/app/general/util/util";
import { NavigationService } from "../services/navigation.service";
import { ToolbarAction } from "src/app/general/components/toolbar/toolbar-action";
import { ProtoType } from "../util/constants";
import { SessionService } from "../services/session.service";
import { EnvironmentUtil } from "../util/environment-util";
import { ToolbarUtil } from "../util/toolbar-util";
import { BackendService } from "../services/backend.service";
import { DialogService } from "src/app/general/services/dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "src/app/general/services/toast.service";
import { Formatter } from "../util/formatter";
import { DisputeDialogService } from "../components/dispute-dialog/dispute-dialog.service";
import { ProtoUtil } from "../util/proto-util";

export class OrderToolbarModel {
  sessionService: SessionService;
  backendService: BackendService;
  navigationService: NavigationService;
  translateService: TranslateService;
  dialogService: DialogService;
  disputeDialogService: DisputeDialogService;
  toastService: ToastService;
  compactForMobile: boolean;

  public actions: Array<ToolbarAction<proto.waiternow.common.IOrderProto>>;
  public compactActions: Array<ToolbarAction<proto.waiternow.common.IOrderProto>>;

  /**
   * @param compactForMobile true to show open in new window for mobile devices.
   *                         Useful when the tool bar is part of a data table row.
   */
  constructor(
      sessionService: SessionService,
      backendService: BackendService,
      navigationService: NavigationService,
      translateService: TranslateService,
      dialogService: DialogService,
      disputeDialogService: DisputeDialogService,
      toastService: ToastService,
      compactForMobile: boolean) {
    this.sessionService = sessionService;
    this.backendService = backendService;
    this.navigationService = navigationService;
    this.translateService = translateService;
    this.dialogService = dialogService;
    this.disputeDialogService = disputeDialogService;
    this.toastService = toastService;
    this.compactForMobile = compactForMobile;

    this.actions =
    [
      {
        icon: 'business',
        tooltipTranslateId: 'go_to_location',
        onExecute: order => this.navigationService.goToLocationPage(Util.safeString(order?.redundantData?.locationId))
      },
      {
        icon: 'receipt',
        tooltipTranslateId: 'order_receipt',
        onExecute: (order: proto.waiternow.common.IOrderProto | undefined) => {
          if (order) {
            this.navigationService.openInNewTab(EnvironmentUtil.resolveBackendUrl('/service/user/order/receipt/' + order.id));
          }
        }
      },
      ToolbarUtil.createToolbarActionEdit(
        order => this.navigationService.goToEditOrderPage(Util.safeString(order.id))),
      {
        icon: 'done',
        tooltipTranslateId: 'mark_as_acked',
        onExecute: order => this.markAsAcked(order)
      },
      {
        icon: 'subdirectory_arrow_left',
        tooltipTranslateId: 'refund',
        onExecute: order => this.refund(order)
      },
      {
        icon: 'sports_kabaddi',
        tooltipTranslateId: 'mark_as_disputed',
        onExecute: order => this.markAsDisputed(order)
      },
      ToolbarUtil.createToolbarActionViewProto(ProtoType.ORDER, order => Util.safeString(order.id), this.sessionService)
    ];

    if (this.compactForMobile) {
      const openInNewWindowAction: ToolbarAction<proto.waiternow.common.IOrderProto> =
          ToolbarUtil.createToolbarActionOpenInNewWindow(
              order => this.navigationService.goToOrderPage(Util.safeString(order.id)));
      Util.insertIntoArray(openInNewWindowAction, 0, this.actions);
      this.compactActions = [openInNewWindowAction];
    } else {
      this.compactActions = this.actions;
    }
  }

  private markAsAcked(order: proto.waiternow.common.IOrderProto | undefined) {
    if (!order) {
      return;
    }
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_mark_order_as_acked').subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.markOrderAsAcked(
            Util.safeString(order.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('order_acked').subscribe(text => this.toastService.success(text));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_internal').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }

  private refund(order: proto.waiternow.common.IOrderProto | undefined) {
    if (!order) {
      return;
    }
    const closeProgressDialog = this.dialogService.openProgressDialog();
    this.translateService.get('confirmation_refund_order', { total: Formatter.formatMoney(order.status?.payment?.total) }).subscribe(text => {
      this.dialogService.openConfirmationDialog(
        text,
        /* onYes */ () => {
          this.backendService.refundOrder(
            Util.safeString(order.id),
            /* onSuccess= */ () => {
              closeProgressDialog();
              this.translateService.get('order_refunded').subscribe(text => this.toastService.success(text));
            },
            /* onError */ error => {
              closeProgressDialog();
              this.translateService.get('error_refunding_order').subscribe(text => this.toastService.error(text));
            }
          );
        },
        /* onNo= */ () => {
          closeProgressDialog();
        });
    });
  }

  private markAsDisputed(order: proto.waiternow.common.IOrderProto | undefined) {
    if (!order) {
      return;
    }

    this.disputeDialogService.openDialog(
      /* onUpdate */ outputDialogData => {
        const disputeDate = ProtoUtil.dateToDateProto(outputDialogData.disputeDate);
        if (!disputeDate) {
          this.translateService.get('failed_operation', {error: 'Invalid date'}).subscribe(text => this.toastService.error(text));
          return;
        }
        const closeProgressDialog = this.dialogService.openProgressDialog();
        this.backendService.markOrderAsDisputed(
          Util.safeString(order.id),
          disputeDate,
          outputDialogData.reason,
          /* onSuccess= */ () => {
            closeProgressDialog();
            this.translateService.get('success_operation').subscribe(text => this.toastService.success(text));
          },
          /* onError */ error => {
            closeProgressDialog();
            this.translateService.get('failed_operation', {error: ''}).subscribe(text => this.toastService.error(text));
          }
        );
      }
    );
  }
}
